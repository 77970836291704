<!--<template>-->
<!--    <div-->
<!--        :class="{'fl-te-c-res': alignEnd, 'fl-x': !alignEnd, 'header-bg':!currentUser.is_superuser,-->
<!--        'header-bg-super':currentUser.is_superuser}"-->
<!--         class="py-3 px-5 fl-j-c c-border text-white fl-a-c border-r-2 bg-4 w-100">-->
<!--        <div class="">-->
<!--            <h5 v-if="heading !==''" class="">{{ heading }}</h5>-->
<!--            <div class="fl-x" v-else>-->
<!--                <slot name="header"></slot>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="btn-group btn-block">-->
<!--            <slot name="buttons"></slot>-->
<!--        </div>-->
<!--    </div>-->
<!--</template>-->

<!--<script>-->
<!--import { mapGetters } from 'vuex';-->

<!--export default {-->
<!--    name  : 'HeaderTag',-->
<!--    props : {-->
<!--        heading : {-->
<!--            type    : String,-->
<!--            default : ''-->
<!--        },-->
<!--        headingBold : {-->
<!--            type    : Boolean,-->
<!--            default : false-->
<!--        },-->
<!--        color : {-->
<!--            type    : String,-->
<!--            default : ''-->
<!--        },-->
<!--        alignEnd : {-->
<!--            type    : Boolean,-->
<!--            default : false-->
<!--        },-->
<!--        accordionHeader : {-->
<!--            type    : Boolean,-->
<!--            default : false-->
<!--        }-->
<!--    },-->
<!--    computed : {-->
<!--        ...mapGetters(['currentUser'])-->
<!--    }-->
<!--};-->
<!--</script>-->

<!--<style lang="scss" scoped>-->
<!--.head-->
<!--er-bg {-->
<!--    //background: url("../../assets/web/bg/blue-layer.png");-->
<!--    background: url("../../assets/web/bg/header.png");-->
<!--    background-size: cover;-->
<!--}-->

<!--.header-bg-super {-->
<!--    //background: url("../../assets/web/bg/blue-layer.png");-->
<!--    background: url("../../assets/web/super-admin/super-admin-header-strip.jpg");-->
<!--    background-size: cover;-->
<!--}-->

<!--h5 {-->
<!--    margin: 0;-->
<!--}-->

<!--.fa {-->
<!--    color: black;-->
<!--}-->

<!--.btn-block {-->
<!--    .lego-btn {-->
<!--        color: black !important;-->

<!--        span {-->
<!--            color: black !important;-->
<!--        }-->
<!--    }-->
<!--}-->

<!--.border-r-1 {-->
<!--    border-radius: 6px 6px 0 0;-->
<!--}-->

<!--.border-r-2 {-->
<!--    border-radius: 9px 9px 0 0;-->
<!--}-->

<!--.c-border {-->
<!--    border-radius: 6px 6px 0px 0px !important;-->
<!--}-->
<!--</style>-->
<template>
    <div
        :class="{'fl-te-c-res': alignEnd, 'fl-x': !alignEnd, 'header-bg':!currentUser.is_superuser,
        'header-bg-super':currentUser.is_superuser}"
         class="py-3 px-5 fl-j-c c-border text-white fl-a-c border-r-2 bg-4 w-100 header-bg">
        <div class="">
            <h5 v-if="heading !==''" class="">{{ heading }}</h5>
            <div class="fl-x" v-else>
                <slot name="header"></slot>
            </div>
        </div>
        <div class="btn-group btn-block">
            <slot name="buttons"></slot>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name  : 'HeaderTag',
    props : {
        heading : {
            type    : String,
            default : ''
        },
        headingBold : {
            type    : Boolean,
            default : false
        },
        color : {
            type    : String,
            default : ''
        },
        alignEnd : {
            type    : Boolean,
            default : false
        },
        accordionHeader : {
            type    : Boolean,
            default : false
        }
    },
    computed : {
        ...mapGetters(['currentUser'])
    }
};
</script>

<style lang="scss" scoped>
.header-bg {
    //background: url("../../assets/web/bg/blue-layer.png");
    background: url("../../assets/web/bg/header.png");
    background-size: cover;
}

.header-bg-super {
    //background: url("../../assets/web/bg/blue-layer.png");
    background: url("../../assets/web/super-admin/super-admin-header-strip.jpg") !important;
    background-size: cover;
}

h5 {
    margin: 0;
}

.fa {
    color: black;
}

.btn-block {
    .lego-btn {
        color: black !important;

        span {
            color: black !important;
        }
    }
}

.border-r-1 {
    border-radius: 6px 6px 0 0;
}

.border-r-2 {
    border-radius: 9px 9px 0 0;
}

.c-border {
    border-radius: 6px 6px 0px 0px !important;
}
</style>
