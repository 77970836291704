<template>
    <div class="bg-2 p-3 bs-5 round-2">
        <slot name="buttons"></slot>
        <div class="row mt-3" v-if="showSearch">
            <validated-input class="col-lg-4 col-md-12" v-model="search" name="search" placeholder="Search"/>
        </div>
        <div v-if="loading">
            <div class="card bg-info mt-4">
                <loading-animation/>
                Please wait while loading data from the server.
            </div>
        </div>

        <div v-else-if="error">
            <div class="card bg-danger mt-4">
                <error-icon/>
                Failed to load data from the server.
            </div>
        </div>

        <div v-else>
            <template v-if="details">
                <slot name="data" :tableData="details.data"></slot>

                <div v-if="details.data.length !== 0" class="mt-3 fl-te-c-res">
                    <div class="mb-2">
                        Showing {{ details.from }} to {{ details.to }} of {{ details.total }} entries
                    </div>
                    <div>
                        <btn class="m-1" size="sm" @click="prevClicked" v-if="prev" type="button"
                             icon="fa fa-chevron-left"/>
                        <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-left"/>
                        <btn class="m-1" size="sm" type="button">{{ details.current_page }}</btn>
                        <btn class="m-1" size="sm" @click="nextClicked" v-if="next" type="button"
                             icon="fa fa-chevron-right"/>
                        <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-right"/>
                    </div>
                </div>

                <div v-if="details.data.length === 0" class="card">
                    <div class="mb-2">
                        <div>
                            <div class="fl-x fl-j-c fl-a-c">
                                No data available
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <slot name="footer"></slot>
    </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';

export default {
    name  : 'ResponsiveDataTable',
    props : {
        listUrl : {
            type    : String,
            default : ''
        },
        showSearch : {
            type    : Boolean,
            default : true
        },
        extraParams : {
            type    : Object,
            default : null
        }
    },
    data () {
        return {
            // Pagination variables
            prev     : '',
            next     : '',
            page     : '1',
            per_page : '5',

            // Data variables
            loading : false,
            search  : '',
            error   : false,
            details : {}
        };
    },

    watch : {
        search : _.debounce(function (newVal) {
            this.loadData();
        }, 500)
    },

    mounted () {
        this.loadData();
    },

    methods : {
        // Initial load data function
        refreshData () {
            this.page = '1';
            this.loadData();
        },
        async loadData () {
            this.loading = true;
            this.error = false;

            const extraParams = this.extraParams;
            const paginationParams = {
                page     : this.page,
                per_page : this.per_page,
                search   : this.search
            };
            const params = { ...paginationParams, ...extraParams };

            try {
                const response = await axios.get(this.listUrl, {
                    params : params
                });
                this.details = response.data;
                this.setPagination();
                this.loading = false;
            } catch (error) {
                this.error = true;
                this.loading = false;
            }
        },

        // Pagination related function
        setPagination () {
            this.prev = parseInt(this.details.current_page) !== this.details.from;
            this.next = parseInt(this.details.current_page) !== this.details.last_page;
        },
        prevClicked () {
            this.page = parseInt(this.page) - 1;
            this.loadData();
        },
        nextClicked () {
            this.page = parseInt(this.page) + 1;
            this.loadData();
        }
    }
};
</script>

<style scoped>
</style>
