<template>
    <div class="card mb-5">
        <div class="fl-te-c pb-3">
            <h4 v-html="title"></h4>

            <div>
                <slot name="buttons"></slot>
            </div>
        </div>

        <div class="ld-over" :class="[{'running': dataLoading}]">

            <div class="ld ld-ring ld-spin" v-if="dataLoading"></div>

            <div class="mb-2 mt-2 fl-te-c">
                <form-input size="sm" placeholder="Search" v-model="search" v-show="showSearchBox"/>

                <div>
                    <span class="text-muted text-small">{{ from }}-{{ to }} of {{ total }}</span>
                </div>
            </div>

            <div v-show="error">
                <slot name="error">
                    <div class="bg-danger p-3">
                        <error-icon/>
                        Loading Data Failed.
                    </div>
                </slot>
            </div>

            <vuetable ref="vuetable" class="order-with-arrow" pagination-path :api-url="url" :per-page="perPageValue"
                      :reactive-api-url="true" :fields="fields"
                      @vuetable:pagination-data="onPaginationData" @vuetable:loading="showLoadingAnimation"
                      @vuetable:load-success="hideLoadingAnimation" @vuetable:load-error="setError"
                      :query-params="makeQueryParams" v-show="!error" :load-on-start="loadOnStart">

                <!--suppress XmlUnboundNsPrefix, JSUnusedLocalSymbols -->
                <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
                    <slot :name="slot" v-bind="scope"/>
                </template>

            </vuetable>
            <custom-vuetable-pagination-bootstrap
                :next-icon="nextIcon" :prev-icon="prevIcon"
                v-show="showPagination" class="mt-4" ref="pagination"
                @vuetable-pagination:change-page="onChangePage"/>
        </div>

        <slot name="footer"></slot>
    </div>
</template>

<script>
import VueTableComponentMixin from '@lego/tables/VueTableComponentMixin';
import CustomVuetablePaginationBootstrap from '@components/lego-custom/CustomVueTablePaginationBootstrap';

export default {
    name       : 'CustomVueTableCard',
    components : { CustomVuetablePaginationBootstrap },
    extends    : VueTableComponentMixin,
    props      : {
        title : { type : String, default : '' }
    }
};
</script>
