import guards from './guards';

const Layout = () => import('../layouts/MainLayout');
// const Home = () => import('../views/cms/Home');

// custom //
const AboutUs = () => import('../views/cms/about-us/about-us/AboutUs');
const AboutUsDetails = () => import('../views/cms/about-us/about-us/DetailsPage');
const VisionAndMission = () => import('../views/cms/about-us/vision-mission/VisionMission');
const VisionAndMissionDetails = () => import('../views/cms/about-us/vision-mission/DetailsPage');
const Team = () => import('../views/cms/our-team/team/Team');
const TeamDetails = () => import('../views/cms/our-team/team/DetailsPage');
const Testimonial = () => import('../views/cms/home/testimonial/Testimonial');
const TestimonialDetails = () => import('../views/cms/home/testimonial/DetailsPage');
const Client = () => import('../views/cms/home/client/Client');
const ClientDetails = () => import('../views/cms/home/client/DetailsPage');
const FAQ = () => import('../views/cms/faq/FAQ');
const FAQDetails = () => import('../views/cms/faq/DetailsPage');
const Enquiries = () => import('../views/cms/contact-us/enquiries/Enquiries');
const ContactUs = () => import('../views/cms/contact-us/contact-us/ContactUs');
const ContactUsDetails = () => import('../views/cms/contact-us/contact-us/DetailsPage');
const PrivacyAndPolicy = () => import('../views/cms/privacy-and-terms/privacy-and-policy/PrivacyAndPolicy');
const PrivacyAndPolicyDetails = () => import('../views/cms/privacy-and-terms/privacy-and-policy/DetailsPage');
const TermsAndConditions = () => import('../views/cms/privacy-and-terms/terms-and-conditions/TermsAndConditions');
const TermsAndConditionsDetails = () => import('../views/cms/privacy-and-terms/terms-and-conditions/DetailsPage');
const SocialMedia = () => import('../views/cms/home/social-media/SocialMedia');
const SocialMediaDetails = () => import('../views/cms/home/social-media/DetailsPage');
const TeamSocialMediaDetails = () => import('../views/cms/our-team/team-social-media-map/DetailsPage');
const MasterData = () => import('../views/cms/master-data/MasterData');
const Banner = () => import('../views/cms/home/banner/BannerListing');
const BannerDetails = () => import('../views/cms/home/banner/DetailsPage');
const Office = () => import('../views/cms/contact-us/office/OfficeListing');
const OfficeDetails = () => import('../views/cms/contact-us/office/DetailsPage');
const Directory = () => import('../views/cms/contact-us/directory/DirectoryListing');
const DirectoryDetails = () => import('../views/cms/contact-us/directory/DetailsPage');
const Media = () => import('../views/cms/media/MediaListing');
const MediaDetails = () => import('../views/cms/media/DetailsPage');

const UpdatePassword = () => import('../views/cms/bank/components/SetNewPasswordAfterLogin');

export default {
    path        : '',
    name        : 'DashboardLayout',
    redirect    : '/cms/',
    component   : Layout,
    beforeEnter : guards.loggedInGuard,
    children    : [
        // {
        //     path: '/cms/',
        //     name: 'home',
        //     component: Home
        // },
        {
            path      : '/cms/about-us/',
            name      : 'AboutUs',
            component : AboutUs
        },
        {
            path      : '/cms/about-us/:id/details/',
            name      : 'AboutUsDetails',
            component : AboutUsDetails
        },
        {
            path      : '/cms/vision-and-mission/',
            name      : 'VisionAndMission',
            component : VisionAndMission
        },
        {
            path      : '/cms/vision-and-mission/:id/details/',
            name      : 'VisionAndMissionDetails',
            component : VisionAndMissionDetails
        },
        {
            path      : '/cms/team/',
            name      : 'Team',
            component : Team
        },
        {
            path      : '/cms/team/:id/details/',
            name      : 'TeamDetails',
            component : TeamDetails
        },
        {
            path      : '/cms/testimonial/',
            name      : 'Testimonial',
            component : Testimonial
        },
        {
            path      : '/cms/testimonial/:id/details/',
            name      : 'TestimonialDetails',
            component : TestimonialDetails
        },
        {
            path      : '/cms/featured-banks/',
            name      : 'Client',
            component : Client
        },
        {
            path      : '/cms/featured-banks/:id/details/',
            name      : 'ClientDetails',
            component : ClientDetails
        },
        {
            path      : '/cms/faq/',
            name      : 'FAQ',
            component : FAQ
        },
        {
            path      : '/cms/faq/:id/details/',
            name      : 'FAQDetails',
            component : FAQDetails
        },
        {
            path      : '/cms/contact-us-banner/',
            name      : 'ContactUs',
            component : ContactUs
        },
        {
            path      : '/cms/enquiries/',
            name      : 'Enquiries',
            component : Enquiries
        },
        {
            path      : '/cms/contact-us-banner/:id/details/',
            name      : 'ContactUsDetails',
            component : ContactUsDetails
        },
        {
            path      : '/cms/privacy-and-policy/',
            name      : 'PrivacyAndPolicy',
            component : PrivacyAndPolicy
        },
        {
            path      : '/cms/privacy-and-policy/:id/details/',
            name      : 'PrivacyAndPolicyDetails',
            component : PrivacyAndPolicyDetails
        },
        {
            path      : '/cms/terms-and-conditions/',
            name      : 'TermsAndConditions',
            component : TermsAndConditions
        },
        {
            path      : '/cms/terms-and-conditions/:id/details/',
            name      : 'TermsAndConditionsDetails',
            component : TermsAndConditionsDetails
        },
        {
            path      : '/cms/social-media/',
            name      : 'SocialMedia',
            component : SocialMedia
        },
        {
            path      : '/cms/social-media/:id/details/',
            name      : 'SocialMediaDetails',
            component : SocialMediaDetails
        },
        {
            path      : '/cms/team-social-media/:id/details/',
            name      : 'TeamSocialMediaDetails',
            component : TeamSocialMediaDetails
        },
        {
            path      : '/cms/master-data/',
            name      : 'MasterData',
            component : MasterData
        },
        {
            path      : '/cms/banner/',
            name      : 'Banner',
            component : Banner
        },
        {
            path      : '/cms/banner/:id/details/',
            name      : 'BannerDetails',
            component : BannerDetails
        },
        {
            path      : '/cms/office/',
            name      : 'Office',
            component : Office
        },
        {
            path      : '/cms/office/:id/details/',
            name      : 'OfficeDetails',
            component : OfficeDetails
        },
        {
            path      : '/cms/directory/',
            name      : 'Directory',
            component : Directory
        },
        {
            path      : '/cms/directory/:id/details/',
            name      : 'DirectoryDetails',
            component : DirectoryDetails
        },
        {
            path      : '/cms/:media_type/media/',
            name      : 'Media',
            component : Media
        },
        {
            path      : '/cms/:media_type/media/:id/details/',
            name      : 'MediaDetails',
            component : MediaDetails
        },
        {
            path      : '/cms/update-password/',
            name      : 'UpdatePassword',
            component : UpdatePassword
        },
        {
            path      : '/cms/how-it-works/',
            name      : 'how-it-works',
            component : () => import('@/views/cms/about-us/how-it-works/HowItWorks')
        },
        {
            path      : '/cms/our-features/',
            name      : 'our-features',
            component : () => import('@/views/cms/about-us/our-features/OurFeatures')
        }
    ]
};
