import Vue from 'vue';
import VueRouter from 'vue-router';
import appRoutes from './app-layout';
import cmsRoutes from './cms-layout';
import authRoutes from './auth';

Vue.use(VueRouter);

const routes = [
    appRoutes,
    authRoutes,
    cmsRoutes
];

if (process.env.NODE_ENV !== 'production') {
    const docsRoutes = require('lego-framework/src/router/docs-layout');
    routes.push(docsRoutes.default);
}

const router = new VueRouter({
    mode : 'history',
    base : process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {
                x : 0,
                y : 0
            };
        }
    }
});

// router.beforeEach((to, from, next) => {
//     if ((to.path === '/login/' || to.path === '/') && (from.path !== '/login/' && from.path !== '/')) {
//         window.location.reload();
//     } else {
//         next();
//     }
// });

router.beforeResolve((to, from, next) => {
    if (to.name) {
    }
    next();
});

router.afterEach(() => {
});

export default router;
