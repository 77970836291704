import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import lego from './plugins/lego';

import 'lego-framework/src/scss/variables/lego-variables.scss';
import './assets/style.scss';

import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import CoolLightBox from 'vue-cool-lightbox';

import 'font-icons/nunito/nunito.css';
import 'font-icons/simple-line-icons/css/simple-line-icons.css';
import 'font-icons/iconsmind-s/css/iconsminds.css';
import 'font-icons/nucleo/css/nucleo.css';
import VueNumber from 'vue-number-animation';
import Box from 'lego-framework/src/containers/Box';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'secure-axios';
import urls from './data/urls';

Vue.config.productionTip = false;

Vue.use(lego);

const win = window;
Vue.use(axios, {
    sessionTimeoutTimer : {
        seconds : 900,
        router,
        url     : '/status/'
    },
    ignoreUnAuthorisedErrorURLs : [urls.auth.status],
    unAuthorisedError           : function (response) {
        console.log(response);
        alert('You have been logged out. Please re-login.');
        win.location = location;
        return response;
    }
});

Vue.use(VueNumber);

Vue.use(CoolLightBox);

Vue.component('Box', Box);
// AOS.init({ delay: 2500 });

new Vue({
    router,
    store,
    render : h => h(App)
}).$mount('#app');

document.onreadystatechange = function () {
    if (document.readyState === 'complete') {
        AOS.init();
    }
};
