import Vue from 'vue';
import Vuex from 'vuex';
import user from './user';

Vue.use(Vuex);

export default new Vuex.Store({
    state : {
        superAdmin : true
    },
    mutations : {},
    actions   : {},
    getters   : {
        getSuperAdmin (state) {
            return state.superAdmin;
        }
    },
    modules : {
        user
    }
});
