import guards from '@/router/guards';

const Layout = () => import(/* webpackChunkName: "login" */'../layouts/BlankLayout');
// const Login = () => import('../views/auth/Login');

export default {
    path        : '/',
    name        : 'AuthLayout',
    redirect    : '/login/',
    component   : Layout,
    beforeEnter : guards.statusGuard,
    children    : [
        {
            path      : '/logout/',
            name      : 'Logout',
            component : () => import('../views/auth/Logout')
        },
        {
            path      : '/status/',
            name      : 'Status',
            component : () => import('../views/auth/CheckStatus')
        },
        {
            path      : '/login/',
            name      : 'Login',
            component : () => import('../views/auth/Login')
        },
        {
            path      : '/verify-mobile/',
            name      : 'VerifyMobile',
            component : () => import('../views/auth/VerifyMobilePage')
        },
        {
            path      : '/forgot-password/',
            name      : 'ForgotPassword',
            component : () => import('../views/auth/ForgotPassword')
        },
        {
            path      : '/set-new-password/:username/',
            name      : 'SetNewPassword',
            component : () => import('../views/auth/SetNewPassword')
        },
        {
            path      : '/super-admin-login/',
            name      : 'SuperAdminLogin',
            component : () => import('../views/auth/super-admin-auth/SuperAdminLogin')
        },
        {
            path      : '/super-admin-verify-mobile/',
            name      : 'SuperAdminVerifyMobile',
            component : () => import('../views/auth/super-admin-auth/SuperAdminVerifyMobile')
        },
        {
            path      : '/super-admin-forgot-password/',
            name      : 'SuperAdminForgotPassword',
            component : () => import('../views/auth/super-admin-auth/SuperAdminForgotPassword')
        },
        {
            path      : '/super-admin-set-new-password/',
            name      : 'SuperAdminSetNewPassword',
            component : () => import('../views/auth/super-admin-auth/SuperAdminSetNewPassword')
        },
        {
            path      : '/super-admin-password-updated/',
            name      : 'SuperAdminPasswordUpdated',
            component : () => import('../views/auth/super-admin-auth/SuperAdminPasswordUpdateSuccess')
        }
    ]
};
