const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api/';
} else {
    // baseUrl = '/';
    baseUrl = '/api/';
}

const subBaseUrl = 'auction/';

export default {
    auth : {
        login          : baseUrl + 'auth/admin-login/',
        logout         : baseUrl + 'auth/logout/',
        status         : baseUrl + 'auth/status/',
        verify         : baseUrl + 'auth/admin-otp-verify/',
        updatePassword : baseUrl + 'auth/password-update/',
        resend         : baseUrl + 'auth/admin/resend-otp/',
        notifications  : baseUrl + 'auth/notification/list/',

        profileUpdate : baseUrl + 'auth/admin/profile-update/',

        dashboard          : baseUrl + 'auth/admin-dashboard/',
        bankAdminDashboard : baseUrl + 'auth/bank-admin-dashboard/',

        generateResetLink : baseUrl + 'auth/admin/generate/password-rest/link/',
        resetPassword     : baseUrl + 'auth/admin/reset-password/'
    },

    userList : {
        markAsRead          : baseUrl + 'auction/auction-enquiry/mark-read/',
        enquiryListDownload : baseUrl + 'auction/auction-enquiry/report-download/',
        enquiryList         : baseUrl + 'auction/auction-enquiry/list/',
        enquiryDetails      : baseUrl + 'auction/auction-enquiry/detail/',

        requestDataList    : baseUrl + 'auth/request-mail-list/',
        requestDataDetails : baseUrl + 'auth/request-mail-details/',
        addEdit            : baseUrl + 'auth/user/create/',
        delete             : baseUrl + 'auth/user/delete/',
        info               : baseUrl + 'auth/user/detail/',
        usersOptions       : baseUrl + 'auth/user/select/',
        roleOptions        : baseUrl + 'auth/user/list/',
        list               : baseUrl + 'auth/user/list/',

        customerDetails : baseUrl + 'auth/customer/details/',

        usernameCheck : baseUrl + 'auth/username-check/',
        emailCheck    : baseUrl + 'auth/email-check/',
        mobileCheck   : baseUrl + 'auth/mobile-number-check/',

        statusUpdate : baseUrl + 'auth/user-status/update/',

        venturaCustomers : baseUrl + 'auth/customer-user/list/',

        customerSuspend : baseUrl + 'auth/customer/activate-suspend/',

        biddersList : {
            List : baseUrl + 'auction/bidders-list/list/',
            info : baseUrl + 'auction/bidders-list/details/'
        },

        venturaAdmin : {
            addEdit      : baseUrl + 'auth/ventura-admin/create/',
            venturaAdmin : baseUrl + 'auth/ventura-user/list/'
        },

        bankAdmin : {
            addEdit   : baseUrl + 'auth/bank-admin/create/',
            bankAdmin : baseUrl + 'auth/bank-user/list/',

            bankDataFetch : baseUrl + 'auth/bank-admin/bank-details/fetch/',
            bankUserList  : baseUrl + 'auth/bank-admin/bank-users/list/'
        }
    },

    auction : {
        updateParticipantEmd       : baseUrl + 'auction/participant/create/',
        participantEmdFormDownload : baseUrl + 'auction/participant/emd-form/',
        participantFetch           : baseUrl + 'auction/participant/username-fetch/',
        refund                     : {
            participantDetails  : baseUrl + 'auction/participant/info/',
            participantList     : baseUrl + 'auction/participant/list/',
            refundList          : baseUrl + 'auction/auction-completed/list/',
            refundInitiatedList : baseUrl + 'auction/refund-auction/initiated-list/',
            refundUpdate        : baseUrl + 'auction/refund-auction/update-refund/',
            bidderRefundDetails : baseUrl + 'auction/auction-refund/details/'
        },

        auction : {
            cancelHistory         : baseUrl + subBaseUrl + 'cancel-history/',
            addEdit               : baseUrl + subBaseUrl + 'auction/add-edit/',
            delete                : baseUrl + subBaseUrl + 'auction/delete/',
            list                  : baseUrl + subBaseUrl + 'auction/list/',
            info                  : baseUrl + subBaseUrl + 'auction/info/',
            editDataFetch         : baseUrl + subBaseUrl + 'edit-data-fetch/',
            formInitialization    : baseUrl + subBaseUrl + 'auction/form-initialization/',
            draftedAuctionList    : baseUrl + subBaseUrl + 'auction/drafted/list/',
            draftedAuctionDetails : baseUrl + subBaseUrl + 'auction/drafted/detail/',

            publish : baseUrl + subBaseUrl + 'auction/publish/',
            suspend : baseUrl + subBaseUrl + 'auction/suspend/',

            auctionItem : {
                addEdit   : baseUrl + subBaseUrl + 'auction/item/add-edit/',
                delete    : baseUrl + subBaseUrl + 'auction/item/delete/',
                list      : baseUrl + subBaseUrl + 'auction/item/list/',
                formMount : baseUrl + subBaseUrl + 'auction/item/form-mount/'
            },

            auctionImage : {
                addEdit : baseUrl + subBaseUrl + 'auction/image/add-edit/',
                delete  : baseUrl + subBaseUrl + 'auction/image/delete/',
                list    : baseUrl + subBaseUrl + 'auction/image/list/'
            },

            auctionVideo : {
                addEdit : baseUrl + subBaseUrl + 'auction/video/add-edit/',
                delete  : baseUrl + subBaseUrl + 'auction/video/delete/',
                list    : baseUrl + subBaseUrl + 'auction/video/list/'
            },
            live : {
                list           : baseUrl + subBaseUrl + 'auction/live/list/',
                stateOptions   : baseUrl + subBaseUrl + 'auction/state/vue-select/',
                details        : baseUrl + subBaseUrl + 'auction/live/details/',
                cancel         : baseUrl + subBaseUrl + 'auction/live/cancel/',
                // reportDownload : baseUrl + subBaseUrl + 'auction/report/download/',
                reportDownload : baseUrl + subBaseUrl + 'auction/report/pdf/download/',
                summaryView    : baseUrl + subBaseUrl + 'auction/summary/view/',
                accessLog      : baseUrl + subBaseUrl + 'auction/access/view/'
            }

        },

        participant : {
            list             : baseUrl + subBaseUrl + 'participant/list/',
            info             : baseUrl + subBaseUrl + 'participant/info/',
            emdFormList      : baseUrl + subBaseUrl + 'participant/emd-submission/list/',
            changeStatusView : baseUrl + subBaseUrl + 'participant/emd-submission/approve-reject/view/'
        },

        bank : {
            addEdit      : baseUrl + 'auth/bank/create/',
            list         : baseUrl + 'auth/bank/list/',
            info         : baseUrl + 'auth/bank/detail/',
            delete       : baseUrl + 'auth/bank/delete/',
            bankOptions  : baseUrl + 'auth/bank/vue-select/',
            statusUpdate : baseUrl + 'auth/bank/status-update/'

        },

        auctionList : {
            stateVueSelect    : baseUrl + 'master-data/state/vue-select/',
            districtVueSelect : baseUrl + 'master-data/district/vue-select/',
            propertyVueSelect : baseUrl + 'master-data/property-type/vue-select/'
        }
    },

    userRoles : {
        addEdit     : baseUrl + 'auth/user-roles/add-edit/',
        roleDetails : baseUrl + 'auth/user-roles/info/'
    },

    reports : {
        auctionDetail                 : baseUrl + 'auth/report/auction-details/',
        completedAuction              : baseUrl + 'auth/report/completed-auction/',
        userDetail                    : baseUrl + 'auth/report/bank-user/',
        bankList                      : baseUrl + 'auth/report/banks-list/',
        venturaCustomerList           : baseUrl + 'auth/report/ventura-customer-list/',
        auctionsList                  : baseUrl + 'auth/report/auction-list/',
        completedAndEndedAuctionsList : baseUrl + 'auth/report/completed-auction-list/',
        liveAuctionsList              : baseUrl + 'auth/report/live-auction-list/'
    },
    payment : {
        initiate_transaction : baseUrl + 'payments/transaction/initiate/',
        process_transaction  : baseUrl + 'payments/transaction/process/'
    },
    transactions : {
        listUrl                   : baseUrl + 'payment/transaction/list/',
        detailsUrl                : baseUrl + 'payment/transaction/details/',
        refundOrCancelTransaction : baseUrl + 'payments/transaction/refund-cancel/',
        checkTransactionStatus    : baseUrl + 'payments/transaction/transaction-status/'
    }

};
