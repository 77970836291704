<template>
    <base-form-control v-bind="formControlAttributes" @clear-clicked="clear">
        <slot name="prepend"></slot>
        <label class="form-file">
            <span v-if="text === ''">Browse</span>
            <span v-text="text"></span>
            <input v-on="listeners" @change="updateValue" :disabled="disabled"
                   :accept="accept"
                   type="file"/>
        </label>
        <slot name="append"></slot>
    </base-form-control>
</template>

<script>
import BaseFormControl from 'lego-framework/src/forms/BaseFormControl';
import BaseInput from 'lego-framework/src/forms/BaseInput';

export default {
    name       : 'CustomFileField',
    components : { BaseFormControl },
    props      : {
        accept : {
            type    : String,
            default : 'application/pdf,application/vnd.ms-excel,application/docx,image/jpeg,image/png,image/jpg,video/mp4,video/x-m4v,video/*'
        }
    },
    extends : BaseInput,
    data () {
        return {
            text : ''
        };
    },
    methods : {
        updateValue (evt) {
            const target = evt.target;
            const files = Array.from(target.files);
            this.files = files;
            const len = files.length;
            if (len === 0) {
                return;
            }

            if (len === 1) {
                const file = files[0];
                this.text = file.name;
            } else if (len > 1) {
                this.text = `${len} files selected`;
            }

            this.$emit('input', this.files);
        }
    }

};
</script>
