import Vuetable from 'vuetable-2/src/components/Vuetable';
import VueTablePaginationBootstrap from '../CustomVueTablePaginationBootstrap';
import config from '../../../assets/config.json';
import _ from 'lodash';

export default {
    props : {
        loadOnStart    : { type : Boolean, default : true },
        fields         : { type : Array, required : true },
        url            : { type : String, required : true },
        perPage        : { type : Number, default : 10 },
        extraParams    : { type : Object, default : null },
        showPagination : { type : Boolean, default : true },

        activeShadow : { type : String, default : '' },
        nextIcon     : { type : String, default : config.pagination.nextPageIcon },
        prevIcon     : { type : String, default : config.pagination.prevPageIcon }
    },

    watch : {
        extraParams : _.debounce(function (newVal) {
            console.log('test debounce');
            this.refreshTable();
        }, 2000),

        perPage : function () {
            this.updatePerPage();
        },

        // search : function () {
        //     this.refreshTable();
        // },

        search : _.debounce(function (newVal) {
            console.log('test search');
            this.refreshTable();
        }, 10000)

    },

    data : function () {
        return {
            dataLoading : false,
            error       : false,

            page : 1,

            from         : 0,
            to           : 0,
            total        : 0,
            lastPage     : 0,
            perPageValue : 10,

            search       : '',
            filterValues : {}
        };
    },

    beforeMount () {
        this.perPageValue = this.perPage;
    },

    methods : {
        makeQueryParams (sortOrder, currentPage) {
            this.selectedItems = [];
            let data = {
                page     : currentPage,
                per_page : this.perPageValue,
                search   : this.search,

                ...this.filterValues
            };

            if (this.extraParams) {
                data = {
                    ...this.extraParams,
                    ...data
                };
            }

            const firstSortOrder = sortOrder[0];
            return firstSortOrder
                ? {
                    sort : firstSortOrder
                        ? firstSortOrder.field + '|' + firstSortOrder.direction
                        : '',
                    ...data
                }
                : {
                    ...data
                };
        },

        onFilter (filterValues) {
            this.filterValues = filterValues;
            // this.refreshTable();
        },

        onPaginationData (paginationData) {
            this.from = paginationData.from;
            this.to = paginationData.to;
            this.total = paginationData.total;
            this.lastPage = paginationData.last_page;
            this.items = paginationData.data;
            this.$refs.pagination.setPaginationData(paginationData);
            this.emitPaginationUpdated();
        },

        emitPaginationUpdated () {
            this.$emit('pagination-updated', {
                from     : this.from,
                to       : this.to,
                total    : this.total,
                lastPage : this.lastPage
            });
        },

        updatePerPage () {
            this.perPageValue = this.perPage;
        },

        refreshTable () {
            this.$refs.vuetable.refresh();
        },

        showLoadingAnimation () {
            this.error = false;
            this.dataLoading = true;
        },

        hideLoadingAnimation () {
            this.error = false;
            this.dataLoading = false;
        },

        setError () {
            this.error = true;
            this.dataLoading = false;
        },

        onChangePage (page) {
            this.$refs.vuetable.changePage(page);
        },

        changePageSize (perPage) {
            this.perPageValue = perPage;
            this.refreshTable();
        },

        getData () {
            return this.$refs.vuetable.tableData;
        },

        setData (data) {
            return this.$refs.vuetable.setData(data);
        }
    },

    components : {
        vuetable                        : Vuetable,
        'vuetable-pagination-bootstrap' : VueTablePaginationBootstrap
    }

};
