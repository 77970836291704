import guards from './guards';

const Layout = () => import('../layouts/MainLayout');
const Home = () => import('../views/Home');

export default {
    path        : '/',
    name        : 'DashboardLayout',
    redirect    : '/',
    component   : Layout,
    beforeEnter : guards.loggedInGuard,
    children    : [
        {
            path      : '/',
            name      : 'Home',
            component : Home
        },
        {
            path      : '/transactions/',
            name      : 'Transactions',
            component : () => import('../views/payment/Transactions')
        },
        {
            path      : '/request-data-list/',
            name      : 'RequestDataList',
            component : () => import('../views/request-data-list/RequestDataList')
        },
        {
            path      : '/request-data-list/:id/details/',
            name      : 'TransactionDetails',
            component : () => import('../../../ventura-bank-admin-front-end-vue-lego/src/views/request-data-list/RequestDataDetailsPage')
        },
        {
            path      : '/enquiry-list/',
            name      : 'EnquiryList',
            component : () => import('../views/enquiry/EnquiryList')
        },
        {
            path      : '/enquiry-list/:id/details/',
            name      : 'TransactionDetails',
            component : () => import('../../../ventura-bank-admin-front-end-vue-lego/src/views/enquiry/EnquiryDetailsPage')
        },
        {
            path      : '/transactions/:id/details/',
            name      : 'TransactionDetails',
            component : () => import('../../../ventura-bank-admin-front-end-vue-lego/src/views/payment/TransactionDetailsPage')
        },
        {
            path      : '/drafted-auctions-list/',
            name      : 'DraftedAuctionsList',
            component : () => import('../views/auction/DraftedAuctionsListPage')
        },
        {
            path      : '/auctions-list/',
            name      : 'AuctionsList',
            component : () => import('../views/auction/AuctionsListPage')
        },
        {
            path      : '/auctions-list/:status/',
            name      : 'AuctionsList',
            component : () => import('../views/auction/AuctionsListPage')
        },
        {
            path      : '/auctions-finished-admin/',
            name      : 'AuctionFinishedAdmin',
            component : () => import('../views/auction/AuctionFinishedAdmin')
        },
        {
            path      : '/auction/:id/details/',
            name      : 'AuctionDetails',
            component : () => import('../views/auction/AuctionDetailsPage')
        },
        {
            path      : '/live-auction/',
            name      : 'LiveAuctionPage',
            component : () => import('../views/auction/LiveAuctionPage')
        },
        {
            path      : '/live-auction-details/:id/',
            name      : 'LiveAuctionDetailsPage',
            component : () => import('../views/auction/LiveAuctionDetailsPage')
        },
        {
            path      : '/manage-users/:selectedIndex/',
            name      : 'ManageUsers',
            component : () => import('../views/users/ManageUsersPage')
        },
        {
            path      : '/notifications-page/',
            name      : 'NotificationsPage',
            component : () => import('../views/NotificationsPage')
        },
        {
            path      : '/add-auction/',
            name      : 'AddAuctionPage',
            component : () => import('../views/auction/AddAuctionPage')
        },

        {
            path      : '/edit/:id/auction/',
            name      : 'EditAuctionPage',
            component : () => import('../views/auction/EditAuction')
        },

        {
            path      : '/auction/:id/details/view-participants/',
            name      : 'ViewParticipants',
            component : () => import('../views/manage-submitted-emd/ManageSubmittedEmd')
        },

        {
            path      : '/auction/:id/cancel-history/',
            name      : 'ViewParticipants',
            component : () => import('../views/auction/cancel-auction/CancelHistory')
        },
        {
            path      : '/refund/completed-auctions/',
            name      : 'RefundCompletedAuctions',
            component : () => import('../views/refund/CompletedAuctionsPage')
        },
        {
            path      : '/refund/:id/view-participants/',
            name      : 'RefundViewParticipants',
            component : () => import('../views/refund/ViewParticipantsPage')
        },
        {
            path      : '/refund/participant-bidder-details/',
            name      : 'ParticipantBidderDetails',
            component : () => import('../views/refund/ParticipantBidderDetails')
        },
        {
            path      : '/update-password/',
            name      : 'UpdatePassword',
            component : () => import('../views/auth/UpdatePassword')
        },
        {
            path      : '/users-list/',
            name      : 'UsersListPage',
            component : () => import('../views/users/UsersListPage')
        },
        {
            path      : '/users-list/:status/',
            name      : 'UsersListPageWithFilter',
            component : () => import('../views/users/UsersListPage')
        },
        {
            path      : '/add-user/',
            name      : 'AddUserPage',
            component : () => import('../views/users/AddUserPage')
        },

        {
            path      : '/ventura-admin/add-user/',
            name      : 'AddUserPage',
            component : () => import('../views/users/ventura-admin/AddVenturaAdmin')
        },

        {
            path      : '/bank-admin/:id/add-user/',
            name      : 'AddUserPage',
            component : () => import('../views/users/bank-admin/AddBankAdmin')
        },

        {
            path      : '/bank-admin/add-user/',
            name      : 'AddUserPage',
            component : () => import('../views/users/bank-admin/AddBankAdminFirst')
        },
        {
            path      : '/bidders-list/',
            name      : 'BiddersList',
            component : () => import('../views/bidders/BiddersList')
        },
        {
            path      : '/bidders/:id/details/',
            name      : 'BidderDetails',
            component : () => import('../views/bidders/BidderDetailsPage')
        },
        {
            path      : '/banks-list/',
            name      : 'BanksList',
            component : () => import('../views/banks/BanksList')
        },
        {
            path      : '/banks-list/:status/',
            name      : 'BanksList',
            component : () => import('../views/banks/BanksList')
        },
        {
            path      : '/add-bank/',
            name      : 'AddBank',
            component : () => import('../views/banks/AddBankPage')
        },

        {
            path      : '/profile/',
            name      : 'profile',
            component : () => import('../views/profile/ProfileUpdate')
        },

        {
            path      : '/bank-admin/:id/users/',
            name      : 'bank-admin-users',
            component : () => import('../views/users/bank-admin/BankAdminUserList')
        },

        {
            path      : '/user/:id/details/',
            name      : 'UserDetails',
            component : () => import('../views/users/UserDetailsPage')
        },

        {
            path      : '/user/:id/details/:selectedIndex/',
            name      : 'UserDetails',
            component : () => import('../views/users/UserDetailsPage')
        },

        {
            path      : '/customer/:id/details/:selectedIndex/',
            name      : 'customerDetails',
            component : () => import('../views/users/customer/CustomerDetails')
        },

        {
            path      : '/refund/:id/participants/:refund/details/',
            name      : 'refund-participants-details',
            component : () => import('../views/refund/ParticipantBidderDetails')
        },
        {
            path      : '/refund-template/',
            name      : 'RefundTemplate',
            component : () => import('../views/refund/RefundOrEmdTemplate')
        },
        {
            path      : 'user/permissions/',
            name      : 'UserPermission',
            component : () => import('../views/user-permission/UserPermission')
        },
        {
            path      : '/user/:id/set-permission/',
            name      : 'SetUserPermission',
            component : () => import('../views/user-permission/AddUserPermission')
        },
        {
            path      : '/reports/',
            name      : 'Reports',
            component : () => import('../views/reports/Report')
        }

    ]
};
