<template>
    <div class="row ld-over" :class="[{'running': dataLoading}]">

        <div class="ld ld-ring ld-spin" v-if="dataLoading"></div>

        <div class="col">
            <div class="mb-2 mt-2 fl-te-c">
                <form-input size="sm" placeholder="Search" v-model="search" v-show="showSearchBox"/>

                <div class="">
                    <span class="text-muted text-small mr-1 mb-2">{{ from }}-{{ to }} of {{ total }}</span>
                </div>
            </div>

            <div class="loading" v-show="error">
                <slot name="error">
                    <div class="alert alert-danger">
                        <error-icon/>
                        Loading Data Failed.
                    </div>
                </slot>
            </div>

            <vuetable ref="vuetable" class="order-with-arrow" pagination-path :api-url="url" :per-page="perPageValue"
                      :reactive-api-url="true" :fields="fields" @vuetable:pagination-data="onPaginationData"
                      @vuetable:loading="showLoadingAnimation"
                      @vuetable:load-success="hideLoadingAnimation" @vuetable:load-error="setError"
                      :query-params="makeQueryParams" v-show="!error" :load-on-start="loadOnStart">
                <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
                    <slot :name="slot" v-bind="scope"/>
                </template>
            </vuetable>

            <custom-vuetable-pagination-bootstrap :next-icon="nextIcon" :prev-icon="prevIcon" v-show="showPagination"
                                                  class="mt-4" ref="pagination"
                                                  @vuetable-pagination:change-page="onChangePage"/>
        </div>
    </div>
</template>

<script>
import VueTableComponentMixin from './tables/VueTableComponentMixin';
import CustomVuetablePaginationBootstrap from '@components/lego-custom/CustomVueTablePaginationBootstrap';

export default {
    name       : 'vue-table',
    components : { CustomVuetablePaginationBootstrap },
    extends    : VueTableComponentMixin
};
</script>
